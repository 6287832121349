/* eslint-disable import/no-unresolved */
// routes
import PeopleIcon from '@mui/icons-material/People';
// import Groups2Icon from '@mui/icons-material/Groups2'
import HomeIcon from '@mui/icons-material/Home';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import GroupsIcon from '@mui/icons-material/Groups';

import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: <HomeIcon /> },
      // { title: 'Buy License', path: PATH_DASHBOARD.general.package , icon: ICONS.cart },
      { title: 'Manage My Team', path: PATH_DASHBOARD.general.agentApproval, icon: <PeopleIcon /> },
      { title: 'Groups', path: PATH_DASHBOARD.general.group, icon: ICONS.chat  },
      { title: 'Ranking', path: PATH_DASHBOARD.general.rating, icon: ICONS.analytics },
      // { title: 'License', path: PATH_DASHBOARD.general.license, icon: ICONS.cart },
      // { title: 'Refer', path: PATH_DASHBOARD.general.reffer, icon: ICONS.booking },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
];

export default navConfig;
