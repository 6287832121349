/* eslint-disable no-unused-vars */
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// Images
import LoginBack from '../../assets/image/image.png'
import Formback from '../../assets/image/Rectangle.png'

import LogoImg from '../../assets/image/logo.png'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'start',
    justifyContent:'start'
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(4),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: theme.spacing(7, 6, 5, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 404,
  
  margin: 'start',
  display: 'flex',
 
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(12, 5),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login"
      sx={{
        backgroundImage: `url(${LoginBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
      <RootStyle>
        <HeaderStyle>
          {/* <Logo /> */}
          <Image src={LogoImg} sx={{ width: 120, height: 50, }}/>
          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {/* {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              alt="login"
              src="https://minimal-assets-api.vercel.app/assets/illustrations/illustration_login.png"
            />
          </SectionStyle>
        )}  */}

        <Box 
          sx={{ display:'flex', justifyContent: 'start', padding:0   }} >
          <ContentStyle  sx={{backgroundImage: `url(${Formback})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', width:'484px'}}>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom sx={{ color: 'white', }}>
                  Sign In as Agent
                </Typography>
                <Typography sx={{ color: 'white' }}>Enter your details below.</Typography>
              </Box>

              {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
            </Stack>

            <LoginForm />
            
            {/* {!smUp && ( */}
              <Typography variant="h6" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="h6" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            {/* )} */}
          </ContentStyle>
        </Box>
      </RootStyle>
    </Page>
  );
}
